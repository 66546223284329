import React from 'react';
import {CardColumns} from 'react-bootstrap';

//Component
import CardComponent from '../components/contentComponents/cardComponent';

//Image
import introPv from '../img/pv/introPv.jpg';
import introElektro from '../img/elektro/introElektro.jpg';
import introEdv from '../img/edv/introEdv.jpg';



function projekte() {


    return(

            <CardColumns>
                <a href="/projekte/pv"><CardComponent title="PV-Anlagen" src={introPv}/></a>
                <a href="/projekte/elektro"> <CardComponent title="Elektroinstallationen" src={introElektro}/></a>   
                <a href="/projekte/edv"> <CardComponent title="EDV" src={introEdv}/></a>
            </CardColumns>
    );
}

export default projekte;
