import React from 'react';
import {Container, Row, Col,ListGroup, Jumbotron} from 'react-bootstrap';
import {  BsFillCaretRightFill } from "react-icons/bs";

//Page
import Kontakt from './kontakt';

//Component
import Carousel  from '../components/contentComponents/carouselComponent';

function home () {

    var icon = <BsFillCaretRightFill/>;

    return(
        <div>

            <Jumbotron className="bg-cover banner">
            <div className="overlay"> </div>
                <Container>
                    <div className="display-3 mb-1"><h1>Herzlich willkommen bei RW-Elektrotechnik GmbH & Co. KG.</h1></div>
                    </Container>
            </Jumbotron>

        <Container>
          
        <Row>
            <Col><p>Wir sind Ihr Ansprechpartner für:</p></Col>
        </Row>
        <Row>
            <Col>
        <ListGroup variant="flush">
        <ListGroup.Item> {icon} PV Anlagen</ListGroup.Item>
        <ListGroup.Item> {icon} Elektroinstallationen</ListGroup.Item>
        <ListGroup.Item> {icon} Planung von Elektroanlagen</ListGroup.Item>
        <ListGroup.Item>{icon} Wärmepumpen</ListGroup.Item>
        </ListGroup>
            </Col>
        </Row>
        <br/>
        <Row>
            <Col>
           Bei Fragen rund um die Elektrotechnik, ob im Gewerbeobjekt oder privat bei Ihnen zu Hause, sind Sie bei uns genau richtig.
            </Col>
        </Row>
        <Row>
            <Col> <span className="font-weight-bold"> Wir beraten Sie gerne!</span>
            </Col>
        </Row>
        <br/>
        <Row>

        <Col md={{ span: 10, offset: 1 }}>
                <Carousel/>
        </Col>
           
        </Row>
        <Row>
        <Col><Kontakt /></Col>
        </Row>
        </Container>
        </div>
    );
}

export default home;