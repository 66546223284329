import React from 'react';
import {Card} from 'react-bootstrap';

function projekte(props) {
    
    const handleClick = (value,id) =>{
        props.onClick(value,id);
    }
    return(

    <Card>
        <Card.Img variant="top" src={props.src} onClick={() => handleClick(true,props.id)}/>       
    </Card>

    );
}

export default projekte;




