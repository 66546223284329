import React from 'react';
import {Carousel} from 'react-bootstrap';

//Image
import introPv from '../../img/pv/introPv.jpg';
import introElektro from '../../img/elektro/introElektro.jpg';
import introEdv from '../../img/edv/introEdv.jpg';

function carouselComp() {
    return(

        <Carousel>
        <Carousel.Item> 
        <a href="/projekte/pv">
          <img
            className="d-block w-100"
            src={introPv}
            alt="IntroPv"
          />
          </a>
          <Carousel.Caption>
            <h3>Photovoltaikanlagen</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <a href="/projekte/elektro">
          <img
            className="d-block w-100"
            src={introElektro}
            alt="IntroElektro"
          />
       </a>
          <Carousel.Caption>
            <h3>Elektroinstallation</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <a href="/projekte/edv">
          <img
            className="d-block w-100"
            src={introEdv}
            alt="IntroEdv"
          />
      </a>
          <Carousel.Caption>
            <h3>EDV-Installation</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
}

export default carouselComp;




