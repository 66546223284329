import React from 'react';
import { Jumbotron,Card, Col, Row} from 'react-bootstrap';
import {FiPhoneCall} from 'react-icons/fi';
import {FiMail} from 'react-icons/fi';

function kontakt () {
    return(
<Jumbotron>
<Card className="text-center">
  <Card.Body>
    <Card.Title>RW-Elektrotechnik GmbH & Co. KG</Card.Title>
      
          <Row><Col>Geschäftsführer:</Col></Row> 
          <Row><Col>Rolf Winand</Col></Row>
          <br/>
          
          <Row><Col>Im Grünen Winkel 5</Col></Row> 
          <Row><Col>32805 Horn Bad Meinberg</Col></Row>
          <Row><Col>Deutschland</Col></Row>
          <br/>
          <Row><Col><a href="tel:05233951538"><FiPhoneCall/> 05233 - 95 15 38</a></Col></Row> 
          <Row><Col><a href="mailto:rolf.winand@t-online.de"><FiMail/> rolf.winand@t-online.de</a></Col></Row>
          <br/>

  </Card.Body>
  <Card.Footer>
          <Row><Col>Öffnungszeiten (Nur Telefonisch)</Col></Row> 
          <Row><Col>MO-FR</Col><Col>07:00 - 17:00</Col></Row>
          <Row><Col>SA-SO</Col><Col>Geschlossen</Col></Row>

    </Card.Footer>
</Card>

</Jumbotron>
    );
}

export default kontakt;
