import React from 'react';
import './style/main.scss';
import {Container} from 'react-bootstrap';

import {
  BrowserRouter as Router,
   Switch,
   Route,
   Redirect
 } from "react-router-dom";


//Components & Pages
import Header from './components/headerComponents/headerContainer';
import HomePage from './pages/home';
import ProjektePage from './pages/projekte';
import KontaktPage from './pages/kontakt';
import ImpressumPage from './pages/impressum';
import DatenschutzPage from './pages/datenschutz';

import EdvPage from './pages/edv';
import ElektroPage from './pages/elektro';
import PvPage from './pages/pv';

import Footer from './components/footerComponents/footerContainer';


function App() {
  return (

    <Router>
    <Header />

    <main role="main" className="flex-shrink-0">
    <Container>
    <Switch>

    <Route path="/" exact><Redirect to="/home"></Redirect></Route>

    <Route path="/home" component={HomePage}/>
    <Route path="/kontakt" component={KontaktPage}/>
    <Route path="/projekte"component={ProjektePage} exact/>
    <Route path="/impressum" component={ImpressumPage}/>
    <Route path="/datenschutz" component={DatenschutzPage}/>

    <Route path="/projekte/pv" component={PvPage} />
    <Route path="/projekte/elektro" component={ElektroPage} />
    <Route path="/projekte/edv" component={EdvPage} />
    
    </Switch>
    </Container>
    </main>

    <Footer/>
  </Router>

  );
}

export default App;
