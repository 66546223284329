import React from 'react';
import {CardColumns} from 'react-bootstrap';

//Component
import ImgComponent from '../components/contentComponents/imgComponent';
import LightboxComponent from '../components/contentComponents/lightboxComponent';

//Json
import data from '../img/edv/edvPath.json';



class edv extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          showComponent:false,
          key:0
        };
        
      }
    handleClick = (isOpen,value) =>{
      this.setState({
       showComponent:isOpen,
       key:value
      })
    }
    
    handleClose = (isClose) =>{
      this.setState({
       showComponent:isClose
      })
    }


    render() {
        const imgPath = "/img/edv/gallery/"
        const pathAr = data.map((data) =>
        imgPath+data.name+".jpg"
    )
        const newData = data.map((data) =>
                <ImgComponent key={data.id} id={data.id -1} onClick={this.handleClick} src={imgPath+data.name+".jpg"}/>
            )
        const {showComponent} = this.state

            return(
                <CardColumns>
                    {newData}
                    {showComponent ?
                    <LightboxComponent imgAr={pathAr} photoIndex={this.state.key} onClose={this.handleClose}/> :
                        null
                    }
                  
                </CardColumns>


            );

    }
  }

export default edv;
